import { Link } from "react-router-dom";
import Pdf from "../img/parking.pdf";
import program from "../img/program.pdf";
import Poll from "../Poll";
import mag from "../img/azbowlmag.pdf";
import sportsbook from "../img/sportsbook.png";

function Home() {
  return (
    <div className="content">
      <div className="cards">
        <div className="card program">
          <div className="titleArea">
            <h3>Digital Program</h3>
          </div>
          <div className="bottomText">
            <h6>Get in the game with our digital program</h6>
            <a href={program} target="_blank" rel="noopener noreferrer">
              <button className="actionButton">Open Program</button>
            </a>
          </div>
        </div>

        <div className="card">
          <div className="titleArea raffle"></div>
          <div className="bottomText">
            <h6>
              Buy a raffle ticket!
            </h6>
            <a
              href="https://www.arizonabowl5050.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="actionButton">Buy a Ticket</button>
            </a>
          </div>
        </div>

        <div className="card">
          <div className="titleArea bowlbash"></div>
          <div className="bottomText">
            <h6>
              Taco Bell NYE Bowl Bash - The best New Year's Eve Party in Tucson.
            </h6>
            <a
              href="https://thearizonabowl.com/event/2024-taco-bell-new-years-eve-downtown-bowl-bash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="actionButton">Learn More</button>
            </a>
          </div>
        </div>

        <div className="card">
          <div className="titleArea restaurants"></div>
          <div className="bottomText">
            <h6>
              Bowl ready restaurants and bars
            </h6>
            <a
              href="https://thearizonabowl.com/bowlready/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="actionButton">Explore Tucson</button>
            </a>
          </div>
        </div>

        <div className="card">
          <div className="titleArea">
            <h3>Bowl Merch</h3>
          </div>
          <div className="bottomText">
            <h6>Check out available parking options</h6>
            <a href={"https://shoparizonabowl.com/"} target="_blank" rel="noopener noreferrer">
              <button className="actionButton">See More</button>
            </a>
          </div>
        </div>


        <div className="card">
          <div className="titleArea">
            <h3>Parking Map</h3>
          </div>
          <div className="bottomText">
            <h6>Check out available parking options</h6>
            <a href={Pdf} target="_blank" rel="noopener noreferrer">
              <button className="actionButton">Open Map</button>
            </a>
          </div>
        </div>

      </div>
    </div>
  );
}
export default Home;
