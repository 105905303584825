import logo from "../img/logo.png"
import "./header.css"
import { useState } from "react";
  
function Header() {
    return (
        <div className={"headerContainer"}>
            <img src={logo} className="logo"/>
            <div className="animationModule">
                <h1 className="gradient">Gameday Central</h1>
            </div>
        </div>
  );
}

export default Header;